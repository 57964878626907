<template>
  <Master/>
</template>

<script>
import Master from './pages/Master.vue'

export default {
  name: 'App',
  components: {
    Master
  }
}
</script>
