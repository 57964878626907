<template>
  <div>
    <transition name="slide-fade" @after-enter="afterEnter">
      <div class="antialiased w-full h-full text-center">
        <!-- <button
          v-if="showMoreButton"
          @click="getMoreResults"
          class="
            inline-flex
            justify-end
            rounded-md
            border border-gray-300
            shadow-sm
            px-4
            py-2
            bg-white
            text-base
            font-medium
            text-gray-700
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
          "
        >
          Más comentarios
        </button> -->

        <div class="mt-3 mx-auto max-w-2xl">
          <transition name="fade" appear>
            <p class="">
              <!-- <strong>{{ comment.name }}</strong> -->

              <span
                v-for="(comment, id) in getVisibleComments"
                :key="comment.key"
                class="mr-2"
                :class="{'color-pink': id%2 == 0, 'color-green': id%2 != 0}"
              >
                {{ comment.text }}
              </span>
            </p>
          </transition>
        </div>

        <!-- comment form -->
        <div
          class="
            flex
            mx-auto
            items-center
            justify-center
            mt-5
            mx-8
            mb-4
            max-w-lg
          "
        >
          <div
            ref="commentForm"
            class="w-full max-w-xl rounded-lg px-4 pt-2"
          >
            <div class="flex flex-wrap -mx-3 mb-6">
              <!-- <h2 class="px-4 pt-3 pb-2 text-gray-800 text-lg">Contribuir</h2> -->
              <div class="w-full md:w-full px-3 mb-2 mt-2">
                <!-- <input
                v-model="newComment.name"
                type="text"
                class="
                  bg-white
                  text-gray-700
                  font-medium
                  py-1
                  px-4
                  border border-gray-400
                  rounded
                  tracking-wide
                  mr-1
                  bg-gray-100
                  w-full
                  mb-2
                "
                placeholder="Escribe tu nombre"
              /> -->
                <textarea
                  v-model="newComment.text"
                  class="
                    bg-gray-100
                    rounded
                    border border-gray-400
                    leading-normal
                    resize-none
                    w-full
                    h-20
                    py-2
                    px-3
                    font-medium
                    placeholder-gray-700
                    focus:outline-none focus:bg-white
                    text-gray-700
                  "
                  name="body"
                  placeholder="Escribe algo aquí"
                ></textarea>
              </div>
              <div class="w-full md:w-full flex items-start md:w-full px-3">
                <!-- <div class="flex items-start w-1/2 text-gray-700 px-2 mr-auto">
                <svg
                  fill="none"
                  class="w-5 h-5 text-gray-600 mr-1"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p class="text-xs md:text-sm pt-px">Some HTML is okay.</p>
              </div> -->
                <div class="-mr-1">
                  <button
                    @click="addComment()"
                    class="
                      bg-white
                      text-gray-700
                      font-medium
                      py-1
                      px-4
                      border border-gray-400
                      rounded-lg
                      tracking-wide
                      mr-1
                      hover:bg-gray-100
                    "
                  >
                    Publicar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CommentsDataService from "../services/CommentsDataService";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import { format, register } from "timeago.js";
import { es as locale_es } from "timeago.js/lib/lang";

export default {
  data() {
    return {
      showComments: true,
      comments: [],
      currentPage: 1,
      commentsCount: 0,
      pagination: 5,
      newComment: {
        timestamp: Date.now(),
        // name: "",
        text: "",
      },
    };
  },
  methods: {
    makeCommentsVisible() {
      this.currentPage = 1;
      CommentsDataService.getAll().on("value", (items) => {
        this.onDataChange(items);

        this.showComments = true;
        // Scroll to bottom of comments to focus on comment form
      });
      this.$emit("open");
    },
    hideComments() {

      this.$nextTick(() => {
        this.showComments = false;
      });

      this.$emit("close");
    },
    afterEnter() {
      // this.commentsHeaderCssClasses = "sm:w-1/2 w-full";
      // this.commentsHeaderSpaceCssClasses = "mb-20";
      this.$nextTick(() => {
        let commentForm = this.$refs.commentForm;
        commentForm.scrollIntoView({ behavior: "smooth" });
      });
    },
    // beforeLeave() {
    //   this.commentsHeaderCssClasses = "w-full";
    //   this.commentsHeaderSpaceCssClasses = "";
    // },
    getMoreResults() {
      this.currentPage += 1;
    },
    onDataChange(items) {
      let _comments = [];

      items.forEach((item) => {
        let key = item.key;
        let data = item.val();
        _comments.push({
          key: key,
          // name: data.name,
          text: data.text,
          timeAgo: format(data.timestamp, "es"),
        });
      });

      this.commentsCount = _comments.length;
      this.comments = _comments;
    },
    addComment() {
      if (!this.newComment.text.length) {
        createToast("Por favor escribe un comentario", {
          type: "warning",
          hideProgressBar: true,
          timeout: 3000,
        });
        return;
      }

      this.newComment.timestamp = Date.now();
      CommentsDataService.create(this.newComment);

      this.newComment.text = "";
      // this.newComment.name = "";

      createToast("¡Gracias por tu comentario!", {
        type: "success",
        hideProgressBar: true,
        timeout: 3000,
      });
    },
    refreshList() {
      this.currentIndex = -1;
    },
  },
  computed: {
    getVisibleComments() {
      // return this.comments.slice(-(this.currentPage * this.pagination));
      return this.comments;
    },
    showMoreButton() {
      return this.currentPage * this.pagination <= this.commentsCount;
    },
  },
  mounted() {
    register("es", locale_es);
    this.makeCommentsVisible();
  },
  beforeUnmount() {
    CommentsDataService.getAll().off("value", this.onDataChange);
  },
};
</script>

<style>
</style>