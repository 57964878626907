<template>
  <div class="wrapper">
    <transition name="fade">
      <div
        v-if="isLoading"
        class="
          absolute
          h-screen
          w-screen
          bg-white
          flex
          justify-center
          items-center
        "
      >
        <div class="relative pt-1 w-1/2">
          <div class="flex mb-2 items-center justify-between">
            <div>
              <img
            src="img/titulo-jardin.png"
          />
              <span
                class="
                  text-xs
                  font-semibold
                  inline-block
                  py-1
                  px-2
                  text-gray-600
                "
              >
                Bienvenidos a nuestro jardín: 12 dispositivos para la escena virtual. El dispositivo como mecanismo de composición nos ha permitido poner en marcha una investigación sobre nuestros modos de producción. Bajo la premisa de desmontar un sistema jerárquico de relaciones escénicas, hemos ido juntxs tras las huellas de las memorias que nos constituyen para trasladarlas a escenarios que combinen la biografía y la ficción, lo íntimo y lo público, lo artístico y lo político, y lo hagan desde una construcción plenamente colectiva. Los procedimientos han sido la intervención, la colaboración, la recreación, la arqueología: todo atravesado por la pregunta de cómo hemos llegado a ser lo que somos y cómo podemos seguir creando en situaciones tan extraordinarias como una pandemia. Haz click en los objetos para entrar a los dispositivos.
              </span>
            </div>
           <!-- <div class="text-right">
               <span class="text-xs font-semibold inline-block text-gray-600">
                {{ loadingProgress }}%
              </span>
            </div> -->
          </div>
          <div
            class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200"
          >
            <div
              :style="{ width: loadingProgress + '%' }"
              class="
                shadow-none
                flex flex-col
                text-center
                whitespace-nowrap
                text-white
                justify-center
                bg-gray-800
              "
            ></div>
          </div>

          <transition name="fade">
            <div v-if="showStartButton">
              <button
                @click="start()"
                type="button"
                class="
                  mt-3
                  inline-flex
                  justify-end
                  border border-gray-900
                  shadow-sm
                  px-6
                  py-4
                  bg-gray-800
                  text-base
                  font-medium
                  text-gray-200
                  hover:bg-gray-900
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
                  rounded
                "
              >
                Entrar al jardín
              </button>
            </div>
          </transition>
        </div>
      </div>
    </transition>

    <canvas
      :id="canvasId"
      class="canvas-style"
      :width="width"
      :height="height"
    />

    <transition name="fade">
      <div id="tooltip" role="tooltip">
        {{ tooltipText }}
        <div id="arrow" data-popper-arrow></div>
      </div>
    </transition>

    <transition name="fade">
      <Modal v-if="showModal" :modal-id="selectedItem" @close="onCloseModal">
      </Modal>
    </transition>
  </div>
</template>

<script>
import Modal from "./Modal";

import tippy, { animateFill } from "tippy.js";

import "tippy.js/dist/backdrop.css";
import "tippy.js/animations/shift-away.css";
import "tippy.js/dist/tippy.css"; // optional for styling

import { Howl, Howler } from "howler";

const paper = require("paper");
export default {
  name: "Canvas",
  components: {
    Modal,
  },
  props: ["canvasId"],
  data: () => ({
    path: null,
    paper: null,
    width: window.innerHeight * 2.222222,
    height: window.innerHeight,
    originalWidth: 3840,
    originalHeight: 1728,
    showModal: false,
    modalTitle: "",
    selectedItem: null,
    tooltipText: "",
    isLoading: true,
    loadingProgress: 0,
    tooltipsActive: false,
    showStartButton: false,
    sound: null,
    audioClips: [],
  }),
  methods: {
    reset() {
      this.paper.project.activeLayer.removeChildren();
    },
    start() {
      this.tooltipsActive = true;

      this.sound = new Howl({
        src: "./audio/bg.mp3",
        autoplay: true,
        loop: true,
        volume: 0.8,
        // onend: function() {
        //     console.log('Finished!');
        // }
      });
      // this.sound.play();
      this.isLoading = false;
    },
    pathCreate(paper) {
      paper.activate();
      return new paper.Path({
        strokeColor: "#000000",
        strokeJoin: "round",
        strokeWidth: 1.5,
      });
    },
    createTool(paper) {
      paper.activate();
      return new paper.Tool();
    },
    createSelectablePath(name, center) {
      this.paper.project.importSVG("svg/" + name + ".svg", (item) => {
        var selectablePath = item;

        if (!selectablePath) {
          return;
        }

        selectablePath.opacity = 0;
        selectablePath.position = center;
        selectablePath.scale(this.getReduction); // NOTE fix para que cubra al objeto PNG que está detrás
        // selectablePath.selected = true;

        // tooltip
        // NOTE calculate canvas rect relative to browser window
        let canvas = this.paper.project.view.element;
        var canvasRect = canvas.getBoundingClientRect();

        function generateGetBoundingClientRect(center, size) {
          let result = {
            width: size.width,
            height: size.height,
            top: canvasRect.top + (center.y - size.height / 2),
            right: canvasRect.left + (center.x + size.width / 2),
            bottom: canvasRect.top + (center.y + size.height / 2),
            left: canvasRect.left + (center.x - size.width / 2),
          };
          return () => result;
        }

        // let tooltipText = "";
        // switch (name) {
        //   case "estatua":
        //     tooltipText = "ANGELA G";
        //     break;
        //   case "camino":
        //     tooltipText = "LÍNEAS DE VIDA";
        //     break;
        //   case "mimosa":
        //     tooltipText = "MIMOSA SENSITIVA";
        //     break;
        //   case "avion":
        //     tooltipText = "PAJARITOS DE LEÑA";
        //     break;
        //   case "puerta":
        //     tooltipText = "CALLE LAS ACACIAS";
        //     break;
        //   case "balde":
        //     tooltipText = "ES AGUA QUE REBOSA";
        //     break;
        //   case "maquina":
        //     tooltipText = "MÁQUINA DE LOS RECUERDOS";
        //     break;
        //   case "margaritas":
        //     tooltipText = "CASANDRA";
        //     break;
        //   case "tendedero":
        //     tooltipText = "TENDEDERO DE LAS INTERRUPCIONES";
        //     break;
        //   case "triciclo":
        //     tooltipText = "COLECTIVOS FAMILIARES";
        //     break;
        //   case "compost":
        //     tooltipText = "COMPOST";
        //     break;
        //   case "esquina":
        //     tooltipText = "BACKSTAGE";
        //     break;

        //   default:
        //     break;
        // }
        // this.tooltipText = tooltipText;
        // var tip = tippy(document.createElement("div"), {
        //   content: this.tooltipText,
        //   placement: "auto",
        //   arrow: true, // No funciona con la animación animateFill
        //   getReferenceClientRect: generateGetBoundingClientRect(
        //     center,
        //     selectablePath.bounds
        //   ),
        //   animateFill: true,
        //   plugins: [animateFill],
        //   interactiveDebounce: 75,
        //   onTrigger(instance, event) {
        //     if (!this.tooltipsActive) {
        //       instance.disable();
        //     } else {
        //       instance.enable();
        //     }
        //   },
        // });


        // AUDIOS
        let sound
        switch (name) {
          case "estatua":
            sound = this.audioClips["ANGELA"];
            break;
          case "camino":
            sound = this.audioClips["CRUCE"];
            break;
          case "mimosa":
            sound = this.audioClips["MIMOSA"];
            break;
          case "avion":
            sound = this.audioClips["PAJARITOS"];
            break;
          case "puerta":
            sound = this.audioClips["CALLE"];
            break;
          case "balde":
            sound = this.audioClips["AGUA"];
            break;
          case "maquina":
            sound = this.audioClips["MAQUINA"];
            break;
          case "margaritas":
            sound = this.audioClips["CASANDRA"];
            break;
          case "tendedero":
            sound = this.audioClips["TENDEDERO"];
            break;
          case "triciclo":
            sound = this.audioClips["COLECTIVOS"];
            break;
          case "compost":
            sound = this.audioClips["COMPOSTERA"];
            break;
          case "esquina":
            sound = this.audioClips["BACKSTAGE"];
            break;

          default:
            sound = null;
        }

        selectablePath.onMouseEnter = () => {
          if (!this.tooltipsActive) {
            return;
          }
          this.paper.view.element.style.cursor = "pointer";
          // tip.show();
          if(sound) {
            sound.play()
          }
        };
        selectablePath.onMouseLeave = () => {
          if (!this.tooltipsActive) {
            return;
          }
          this.paper.view.element.style.cursor = "default";
          // tip.hide();
          if(sound) {
            sound.stop()
          }
        };
        selectablePath.onMouseUp = () => {
          if (!this.tooltipsActive) {
            return;
          }
          this.modalTitle = name;
          this.selectedItem = name;
          this.showModal = true;

          this.sound.pause();
          if(sound) {
            sound.stop();
          }

          this.tooltipsActive = false;
        };
      });
    },
    renderBg() {
      return new Promise((resolve, reject) => {
        var bg = new this.paper.Raster({
          source: "img/jardin-back.jpg",
          position: this.paper.view.center,
        });

        var bgSize = new this.paper.Size(this.width, this.height);
        bg.onLoad = () => {
          bg.size = bgSize;
          resolve();
        };
      });
    },
    renderElements(tickCallback) {
      let renderPromises = [
        this.renderBg(),
        this.renderElement("puerta", 1, 1, 2080, 380, false),
        this.renderElement("camino", 1, 1, 2460, 1280, false),
        this.renderElement("compost", 1, 1, 895, 1035, false),
        this.renderSpriteAnimation("tendedero", 878, 882, 3000, 540),
        this.renderElement("triciclo", 675, 520, 2870, 1160),
        this.renderSpriteAnimation("balde", 460, 736, 3250, 1250),
        this.renderSpriteAnimation("maquina", 500, 500, 1700, 1080),
        this.renderSpriteAnimation("mimosa", 900, 1105, 450, 400),
        this.renderElement("margaritas", 1430, 389, 3000, 1535),
        this.renderElement("estatua", 1, 1, 1280, 695, false),
        this.renderElement("avion", 1, 1, 2450, 825, false),
        this.renderSpriteAnimation(
          "esquina",
          181,
          164.5,
          181 / 2,
          164.5 / 2,
          4,
          5
        ),
      ];

      var len = renderPromises.length;
      var progress = 0;

      function tick(promise) {
        promise.then(function () {
          progress++;
          tickCallback(progress, len);
        });
        return promise;
      }

      return Promise.all(renderPromises.map(tick));
    },
    renderElement(name, width, height, x, y, hasImage = true) {
      return new Promise((resolve, reject) => {
        let reduction = this.getReduction;

        let elSize = new this.paper.Size(width * reduction, height * reduction);
        let elPos = new this.paper.Point(x * reduction, y * reduction);

        if (hasImage) {
          let el = new this.paper.Raster({
            source: "img/" + name + ".png",
          });

          el.onLoad = () => {
            el.size = elSize;
            el.position = elPos;
            this.createSelectablePath(name, elPos);

            resolve();
          };
        } else {
          // Si no se necesita renderizar una imagen solo crear área interactiva
          this.createSelectablePath(name, elPos);
          resolve();
        }
      });
    },
    renderSpriteAnimation(name, width, height, x, y, hTiles = 4, vTiles = 3) {
      let paper = this.paper;
      var Sprite = paper.Group.extend({
        _class: "Sprite",
        initialize: function Sprite(url, maskSize, hTiles, vTiles, callback) {
          this.maskSize = maskSize || new paper.Size(256, 256);
          var that = this;

          this._raster = new paper.Raster(url);
          this._raster.pivot = new paper.Point();

          this._raster.on("load", function () {
            this.size.width = that.maskSize.width * hTiles;
            this.size.height = that.maskSize.height * vTiles;
            this.pivot = this.bounds.topLeft;
            that._spriteSheetWidth = hTiles;
            that._spriteSheetHeight = vTiles;

            that.setIndex(that._spriteIndex || 0);

            callback();
          });
          this._clipRect = new paper.Path.Rectangle(
            new paper.Point(),
            this.maskSize
          );

          Sprite.base.call(this, [this._clipRect, this._raster]);
          // Just use a blank point if you want the position to be in the corner
          this.pivot = this.bounds.center;
          this.applyMatrix = false;
          // this.pivot = new paper.Point(this.maskSize.divide(2));

          this.clipped = true;
        },

        setIndex: function (index) {
          if (typeof this._spriteSheetWidth !== "undefined") {
            var column = index % this._spriteSheetWidth;
            var row = (index - column) / this._spriteSheetWidth;
            this._raster.position = new paper.Point(
              -column * this.maskSize.width,
              -row * this.maskSize.height
            );
          }
          return (this._spriteIndex = index);
        },

        getIndex: function () {
          return this._spriteIndex;
        },

        maxIndex: function () {
          if (typeof this._spriteSheetWidth !== "undefined") {
            return this._spriteSheetWidth * this._spriteSheetHeight - 1;
          }
          return null;
        },

        next: function () {
          var i = this._spriteIndex + 1;
          if (i > this.maxIndex()) {
            return this.setIndex(0);
          }
          return this.setIndex(i);
        },
      });

      return new Promise((resolve, reject) => {
        let reduction = this.getReduction;

        let elSize = new this.paper.Size(
          width * reduction,
          height * reduction
        ).floor();
        let elPos = new this.paper.Point(x * reduction, y * reduction);

        let sprite = new Sprite(
          {
            source: "sprites/" + name + ".png",
          },
          elSize,
          hTiles,
          vTiles,
          () => {
            resolve();
          }
        );

        // move to position
        sprite.position = elPos;

        // play animation
        setInterval(function () {
          sprite.next();
          paper.project.view.update();
        }, 150);

        // clickable area
        this.createSelectablePath(name, elPos);
      });
    },
    onCloseModal() {
      this.showModal = false;
      this.tooltipsActive = true;
      this.sound.play();
    },
  },
  computed: {
    getReduction() {
      return this.height / this.originalHeight;
    },
  },
  async mounted() {
    this.paper = new paper.PaperScope();
    this.paper.setup(this.canvasId);

    const audioClipNames = [
        "AGUA",
        "ANGELA",
        "CALLE",
        "CASANDRA",
        "CEMENTERIO",
        "COLECTIVOS",
        "COMPOSTERA",
        "CRUCE",
        "MAQUINA",
        "MIMOSA",
        "PAJARITOS",
        "PELOTA",
        "PUERTA",
        "TENDEDERO",
      ];

      await audioClipNames.forEach((name) => {
        let sound = new Howl({
          src: "./audio/"+name+".mp3",
          autoplay: false,
          loop: false,
          volume: 1,
          // onend: function() {
          //     console.log('Finished!');
          // }
        });
        this.audioClips[name] = sound;
      });

    await this.renderElements((completed, total) => {
      let percentageCompleted = Math.round((completed / total) * 100);
      this.loadingProgress = percentageCompleted;
    });
    this.showStartButton = true;
  },
};
</script>

<style scoped>
.canvas-style {
  /* cursor: crosshair; */
  /* width: 100% !important; */
  /* height: 100vh !important; */
  display: block;
  margin: auto;
  /* object-fit: contain; */
  /* width: auto; */
  /* height: 100%; */
}

/* .wrapper { */
/* height: 100vh; */
/* } */
</style>
