<template>
  <div id="modal-template">
    <div
      class="fixed z-10 inset-0"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="mx-auto mt-6 pb-3 w-100 flex justify-end max-w-screen-xl">
        <button
          @click="$emit('close')"
          type="button"
          style="z-index: 999"
          class="
            fixed
            inline-flex
            justify-end
            px-4
            py-2
            mr-2
            text-base
            font-medium
            text-gray-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="24"
            height="24"
            viewBox="0 0 172 172"
            style="fill: #000000"
          >
            <g
              fill="none"
              fill-rule="nonzero"
              stroke="none"
              stroke-width="1"
              stroke-linecap="butt"
              stroke-linejoin="miter"
              stroke-miterlimit="10"
              stroke-dasharray=""
              stroke-dashoffset="0"
              font-family="none"
              font-weight="none"
              font-size="none"
              text-anchor="none"
              style="mix-blend-mode: normal"
            >
              <path d="M0,172v-172h172v172z" fill="none"></path>
              <g fill="#95a5a6">
                <path
                  d="M33.73372,23.59961l-10.13411,10.13411l52.26628,52.26628l-52.26628,52.26628l10.13411,10.13411l52.26628,-52.26628l52.26628,52.26628l10.13411,-10.13411l-52.26628,-52.26628l52.26628,-52.26628l-10.13411,-10.13411l-52.26628,52.26628z"
                ></path>
              </g>
            </g>
          </svg>
        </button>
      </div>
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
        <div
          class="
            fixed
            inset-0
            bg-white
            transition-opacity
            m-4
            bg-opacity-75
            max-w-screen-xl
            mx-auto
            text-center
          "
          aria-hidden="true"
        ></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >

        <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
        <div
          id="scroll-container"
          class="
            inline-block
            text-left
            transform
            transition-all
            sm:align-middle sm:w-full
            overflow-y-scroll overflow-hidden
          "
          style="max-height: 90vh; margin-top: -4rem"
        >
          <div class="mx-auto">
            <div v-if="modalId == 'esquina'">
              <div class="px-0 pt-0 mb-0">
                <div class="sm:flex sm:items-start">
                  <div class="mt-0 text-center sm:mt-0 w-auto mx-auto">
                    <div class="">
                      <modal-content :show-header="false">
                        <template v-slot:modal-misc>
                          <div class="backstage-slider">
                            <swiper
                              :slides-per-view="1"
                              @swiper="onSwiper"
                              @slideChange="onSlideChange"
                              navigation
                            >
                              <swiper-slide v-for="i in 8" :key="i">
                                <img
                                  :src="'img/claquetas/BACKSTAGE/' + i + '.png'"
                                />
                              </swiper-slide>
                            </swiper>
                          </div>
                        </template>
                      </modal-content>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="px-4 pt-3 sm:p-6 sm:pb-4 m-6 mb-0">
              <div class="sm:flex sm:items-start">
                <div class="mt-3 text-center sm:mt-0 w-auto mx-auto">
                  <div class="">
                    <!-- mostrar componente condicionalmente -->
                    <div v-if="modalId == 'estatua'">
                      <modal-content :show-header="true">
                        <template v-slot:header-title>
                          <img src="img/titulos/ANGELA-Gtrans.png" alt="" />
                        </template>
                        <template v-slot:header-body>
                        </template>
                        <template v-slot:header-right-img>
                          <img
                            src="img/claquetas/ANGELA G/ANGELA-G-ELEMENTO.png"
                            alt=""
                          />
                        </template>
                        <template v-slot:modal-video>
                          <div class="modal-video aspect-w-16 aspect-h-9">
                            <iframe
                              class="tscplayer_inline"
                              id="embeddedSmartPlayerInstance"
                              title="vimeo-player"
                              src="https://player.vimeo.com/video/638795963?h=4358920c92&autoplay=1&title=0&byline=0&portrait="
                              allow="autoplay"
                              width="640"
                              height="360"
                              scrolling="no"
                              frameborder="0"
                              webkitAllowFullScreen
                              mozallowfullscreen
                              allowFullScreen
                            ></iframe>
                          </div>
                        </template>
                      </modal-content>
                    </div>

                    <div v-if="modalId == 'mimosa'">
                      <modal-content :show-header="true">
                        <template v-slot:header-title>
                          <img
                            src="img/titulos/MIMOSA-SENSITIVAtrans.png"
                            alt=""
                          />
                        </template>
                        <template v-slot:header-body>
                        </template>
                        <template v-slot:header-right-img>
                          <img src="img/claquetas/MIMOSA/mimosa.png" alt="" />
                        </template>
                        <template v-slot:modal-video>
                          <div class="modal-video aspect-w-16 aspect-h-9">
                            <iframe
                              class="tscplayer_inline"
                              id="embeddedSmartPlayerInstance"
                              title="vimeo-player"
                              allow="autoplay"
                              src="https://player.vimeo.com/video/638762377?h=2862cdc757&autoplay=1&title=0&byline=0&portrait="
                              width="640"
                              height="360"
                              scrolling="no"
                              frameborder="0"
                              webkitAllowFullScreen
                              mozallowfullscreen
                              allowFullScreen
                            ></iframe>
                          </div>
                        </template>
                      </modal-content>
                    </div>

                    <div v-if="modalId == 'avion'">
                      <modal-content :show-header="true">
                        <template v-slot:header-title>
                          <img
                            src="img/titulos/PAJARITOS-DE-LENAtrans.png"
                            alt=""
                          />
                        </template>
                        <template v-slot:header-body>
                        </template>
                        <template v-slot:header-right-img>
                          <img
                            src="img/claquetas/PAJARITOS DE LENA/PAJARITOS.png"
                            alt=""
                          />
                        </template>

                        <template v-slot:modal-misc>
                          <div class="slider-vertical">
                            <swiper
                              :slides-per-view="1"
                              @swiper="onSwiper"
                              @slideChange="onSlideChange"
                              navigation
                            >
                              <swiper-slide>
                                <img
                                  src="img/claquetas/PAJARITOS DE LENA/1 Como Hago.png"
                                  class="max-h-full"
                                />
                              </swiper-slide>
                              <swiper-slide>
                                <img
                                  src="img/claquetas/PAJARITOS DE LENA/2 Como suena.png"
                                  class="max-h-full"
                                />
                              </swiper-slide>
                              <swiper-slide>
                                <div class="flex justify-center">
                                  <iframe
                                    title="vimeo-player"
                                    src="https://player.vimeo.com/video/638770100?h=5e0ac00b56&autoplay=1&title=0&byline=0&portrait=0"
                                    allow="autoplay"
                                    width="450"
                                    height="550"
                                    frameborder="0"
                                    allowfullscreen
                                  ></iframe>
                                </div>
                              </swiper-slide>
                              <swiper-slide>
                                <div class="flex justify-center">
                                  <iframe
                                    title="vimeo-player"
                                    src="https://player.vimeo.com/video/638770195?h=4406aaa292&autoplay=1&title=0&byline=0&portrait=0"
                                    allow="autoplay"
                                    width="450"
                                    height="550"
                                    frameborder="0"
                                    allowfullscreen
                                  ></iframe>
                                </div>
                              </swiper-slide>
                              <swiper-slide>
                                <div class="flex justify-center">
                                  <iframe
                                    title="vimeo-player"
                                    src="https://player.vimeo.com/video/638770261?h=8b272f9b0d&autoplay=1&title=0&byline=0&portrait=0"
                                    allow="autoplay"
                                    width="450"
                                    height="550"
                                    frameborder="0"
                                    allowfullscreen
                                  ></iframe>
                                </div>
                              </swiper-slide>
                              <swiper-slide>
                                <img
                                  src="img/claquetas/PAJARITOS DE LENA/5.1 INOCENCIA.png"
                                  class="max-h-full"
                                />
                              </swiper-slide>
                              <swiper-slide>
                                <img
                                  src="img/claquetas/PAJARITOS DE LENA/6 Deseo.png"
                                  class="max-h-full"
                                />
                              </swiper-slide>
                              <swiper-slide>
                                <div class="flex justify-center">
                                  <iframe
                                    title="vimeo-player"
                                    src="https://player.vimeo.com/video/638770365?h=9d3ef99d7e&autoplay=1&title=0&byline=0&portrait=0"
                                    allow="autoplay"
                                    width="450"
                                    height="550"
                                    frameborder="0"
                                    allowfullscreen
                                  ></iframe>
                                </div>
                              </swiper-slide>
                            </swiper>
                          </div>
                        </template>
                      </modal-content>
                    </div>

                    <div v-if="modalId == 'puerta'">
                      <modal-content :show-header="true">
                        <template v-slot:header-title>
                          <img
                            src="img/titulos/CALLE-ACACIAStrans.png"
                            alt=""
                          />
                        </template>
                        <template v-slot:header-body>
                        </template>
                        <template v-slot:header-right-img>
                          <img
                            src="img/claquetas/CALLE LAS ACACIAS/ACACIAS.png"
                            alt=""
                          />
                        </template>

                        <template v-slot:modal-misc>
                          <div class="video-slider">
                            <swiper
                              :slides-per-view="1"
                              @swiper="onSwiper"
                              @slideChange="onSlideChange"
                              navigation
                            >
                              <swiper-slide>
                                <div class="flex justify-center">
                                  <iframe
                                    title="vimeo-player"
                                    src="https://player.vimeo.com/video/632372581?h=4ad1cb4095&autoplay=1&title=0&byline=0&portrait="
                                    allow="autoplay"
                                    width="650"
                                    height="750"
                                    frameborder="0"
                                    allowfullscreen
                                  ></iframe>
                                </div>
                              </swiper-slide>
                              <swiper-slide>
                                <div class="flex justify-center">
                                  <iframe
                                    title="vimeo-player"
                                    src="https://player.vimeo.com/video/688865502?h=4ad1cb4095&autoplay=1&title=0&byline=0&portrait="
                                    
                                    width="650"
                                    height="750"
                                    frameborder="0"
                                    allowfullscreen
                                  ></iframe>
                                </div>
                              </swiper-slide>
                              <swiper-slide>
                                <div class="flex justify-center">
                                  <iframe
                                    title="vimeo-player"
                                    src="https://player.vimeo.com/video/688862840?h=4ad1cb4095&autoplay=1&title=0&byline=0&portrait="
                                    
                                    width="650"
                                    height="750"
                                    frameborder="0"
                                    allowfullscreen
                                  ></iframe>
                                </div>
                              </swiper-slide>
                            </swiper>
                          </div>
                        </template>
                      </modal-content>
                    </div>

                    <div v-if="modalId == 'balde'">
                      <modal-content :show-header="true">
                        <template v-slot:header-title>
                          <img src="img/titulos/AGUA-REBOSAtrans.png" alt="" />
                        </template>
                        <template v-slot:header-body>
                        </template>
                        <template v-slot:header-right-img>
                          <img
                            src="img/claquetas/ES AGUA QUE REBOSA/BALDE-Y-LLAVE.png"
                            alt=""
                          />
                        </template>

                        <template v-slot:modal-video>
                          <div class="modal-video aspect-w-16 aspect-h-9">
                            <iframe
                              class="tscplayer_inline"
                              id="embeddedSmartPlayerInstance"
                              title="vimeo-player"
                              src="https://player.vimeo.com/video/642422104?h=9a02f01b27&autoplay=1&title=0&byline=0&portrait="
                              allow="autoplay"
                              width="640"
                              height="360"
                              scrolling="no"
                              frameborder="0"
                              webkitAllowFullScreen
                              mozallowfullscreen
                              allowFullScreen
                            ></iframe>
                          </div>
                        </template>
                      </modal-content>
                    </div>

                    <div v-if="modalId == 'maquina'">
                      <modal-content :show-header="true">
                        <template v-slot:header-title>
                          <img
                            src="img/titulos/MAQUINA-DE-LOS-RECUERDOStrans.png"
                            alt=""
                          />
                        </template>
                        <template v-slot:header-body>
                        </template>
                        <template v-slot:header-right-img>
                          <img
                            src="img/claquetas/MAQUINA DE LOS RECUERDOS/MAQUINA.png"
                            alt=""
                          />
                        </template>

                        <template v-slot:modal-video>
                          <div class="modal-video aspect-w-16 aspect-h-9 mt-4">
                            <iframe
                              class="tscplayer_inline"
                              id="embeddedSmartPlayerInstance"
                              title="vimeo-player"
                              src="./interactivity-cam/PRUEBA INTERRATIVITY CAM_player.html?embedIFrameId=embeddedSmartPlayerInstance"
                              width="640"
                              height="360"
                              scrolling="no"
                              frameborder="0"
                              webkitAllowFullScreen
                              mozallowfullscreen
                              allowFullScreen
                            ></iframe>
                          </div>
                        </template>
                      </modal-content>
                    </div>

                    <div v-if="modalId == 'triciclo'">
                      <modal-content :show-header="true">
                        <template v-slot:header-title>
                          <img
                            src="img/titulos/COLECTIVOS-FAMILIAREStrans.png"
                            alt=""
                          />
                        </template>
                        <template v-slot:header-body>
                        </template>
                        <template v-slot:header-right-img>
                          <img
                            src="img/claquetas/COLECTIVOS/triciclo.png"
                            alt=""
                          />
                        </template>

                        <template v-slot:modal-video>
                          <div class="modal-video aspect-w-16 aspect-h-9">
                            <iframe
                              class="tscplayer_inline"
                              id="embeddedSmartPlayerInstance"
                              title="vimeo-player"
                              src="https://player.vimeo.com/video/645264513?h=a98cf95446&autoplay=1&title=0&byline=0&portrait="
                              allow="autoplay"
                              width="640"
                              height="360"
                              scrolling="no"
                              frameborder="0"
                              webkitAllowFullScreen
                              mozallowfullscreen
                              allowFullScreen
                            ></iframe>
                          </div>
                        </template>
                      </modal-content>
                    </div>

                    <div v-if="modalId == 'compost'">
                      <modal-content :show-header="false">
                        <!-- <template v-slot:header-title>
                          <img
                            src="img/claquetas/COLECTIVOS/COLECTIVOS-FAMILIARES.png"
                            alt=""
                          />
                        </template>
                        <template v-slot:header-body>
                          <strong>Triciclo rojo.</strong> Mi abuela me enseñaba a hablar con las plantas y las flores. Yo les ponía un nombre, a cada una, de acuerdo a como se veían: Fabiola, Laura, Jacinta. Y luego, les enseñaba a bailar. A veces también, desde mi triciclo, imaginaba que eran un colectivo de niñas que me saludaban con sus manitos verdes.
                        </template>
                        <template v-slot:header-right-img>
                          <img
                            src="img/claquetas/COLECTIVOS/triciclo.png"
                            alt=""
                          />
                        </template> -->

                        <template v-slot:modal-misc>
                          <div class="w-full">
                            <div class="grid grid-cols-1 gap-4">
                              <div v-for="i in 73" :key="i">
                                <img
                                  :src="'img/claquetas/COMPOST/' + i + '.jpg'"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </template>
                      </modal-content>
                    </div>

                    <div v-if="modalId == 'camino'">
                      <modal-content :show-header="true">
                        <template v-slot:header-title>
                          <img
                            src="img/titulos/CRUCE-DE-CAMINOStrans.png"
                            alt=""
                          />
                        </template>
                        <template v-slot:header-body>
                        </template>
                        <template v-slot:header-right-img>
                          <img
                            src="img/claquetas/CRUCES DE CAMINOS/CAMINO.png"
                            alt=""
                          />
                        </template>

                        <template v-slot:modal-video>
                          <div class="mt-6">
                            <div
                              class="visme_d"
                              data-url="mxykzo4j-lineas-de-vida"
                              data-w="800"
                              data-h="6500"
                              data-domain="my"
                            ></div>
                            <p
                              style="
                                width: 220px !important;
                                border-radius: 3px !important;
                                padding: 3px !important;
                                font-size: 12px !important;
                                font-family: Arial, sans-serif !important;
                                color: #314152 !important;
                                white-space: nowrap !important;
                              "
                            >
                              Made with
                              <a
                                href="https://www.visme.co/make-infographics?utm_source=CTA&amp;utm_medium=Embed"
                                target="_blank"
                                rel="noreferrer"
                                style="
                                  font-weight: 600 !important;
                                  text-decoration: none !important;
                                  font-size: 12px !important;
                                  font-family: Arial, sans-serif !important;
                                  color: #314152 !important;
                                  white-space: nowrap !important;
                                "
                                >Visme Infographic Maker</a
                              >
                            </p>
                          </div>
                        </template>
                      </modal-content>
                    </div>

                    <div v-if="modalId == 'tendedero'">
                      <modal-content :show-header="true">
                        <template v-slot:header-title>
                          <img src="img/titulos/TENDEDEROtrans.png" alt="" />
                        </template>
                        <template v-slot:header-body>
                        </template>
                        <template v-slot:header-right-img>
                          <img
                            src="img/claquetas/TENDEDERO/TENDEDERO-ELEMENTO.png"
                            alt=""
                          />
                        </template>

                        <template v-slot:modal-misc>
                          <div class="text-left mb-20 mt-20 max-w-3xl">
                            <p class="text-center mb-10">
                              <span class="c1"
                                >41 Interrupciones, un tendedero</span
                              >
                            </p>
                            <p class="italic mb-5">
                              Esto fue pensado originalmente como un tendedero.
                              Entonces, vamos a imaginar que en este espacio
                              bidimensional se despliega, entre dos pesados
                              soportes de hierro, un cordón que es blanco y es
                              liviano. Vamos a imaginar también que cuelgan en
                              ese tendedero, 41 pinzas y en cada una de ellas un
                              texto, que es, por el contrario, no producto de la
                              imaginación, sino constatación de la más absurda y
                              también más salvaje y también más hermosa
                              realidad. Habito la interrupción, dijo ayer la
                              Dolores y en esa frase entran nuestros días, y
                              también nuestras vidas.
                            </p>
                            <p class="italic mb-5">
                              Cada una de estas interrupciones puede ser leída
                              por una de nosotras.
                            </p>

                            <ol>
                              <li class="">
                                <span class="c1"
                                  >&nbsp;Meterme a la ducha es mi primera idea
                                  al abrir los ojos: que el agua tibia
                                  recomponga el cansancio para que el día
                                  empiece. Se escucha entonces la voz del niño
                                  retumbando en la casa y gritando mi nombre
                                  como quien se ahoga, como quien con urgencia
                                  necesita salvarse.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >&nbsp;Interrumpir el sueño porque alguien a
                                  mi lado dice: tengo insomnio, tengo miedo,
                                  tengo una pesadilla, tengo un dolor en el
                                  pecho, escuchas el sonido de la mosca, hagamos
                                  el amor, etc.
                                </span>
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >El camino se interrumpe, ella me dice con la
                                  voz ronca: puta madre se bajó la llanta ¿Y
                                  ahora?</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Sentada frente a la taza de café caliente
                                  suena el timbre y él tiene los audífonos
                                  puestos y timbran más fuerte: voy.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Corro porque correr me hace sentir la huida
                                  como una posibilidad real, pero iniciado el
                                  camino un hombre montado en una bicicleta se
                                  me acerca y me interrumpe: qué bonita la
                                  señora, ¿le acompaño?</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >La imagen es la de una mujer vieja tomando el
                                  sol y luego levantándose porque ha sonado el
                                  timbre y caminando lento y rodando las
                                  escaleras y rompiéndose la cadera y muriéndose
                                  en la más absoluta soledad.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Todo iba bien hasta que el médico dijo
                                  necesita pitusín y así como así, se interrumpe
                                  mi labor de parto y el cuerpo se
                                  enloquece.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Mi gato se sube a mis piernas y pide
                                  caricias. Dejo de escribir y adivino en sus
                                  ojos la tristeza de morir lejos de la nieve,
                                  de su nieve.
                                </span>
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Entonces me levanto porque escucho la voz
                                  firme que pregunta: hoy, ¿qué vamos a
                                  comer?</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Y agitada dice: deja lo que estás haciendo,
                                  esto es urgente.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >A correr pienso: voy a correr. Pero no quiero
                                  correr sola porque el depredador puede atacar
                                  así que dejo los zapatos y le pido a él que me
                                  acompañe, pero no puede interrumpir lo que
                                  está haciendo.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Y frente a la lectura del texto, frente a la
                                  frase: </span
                                ><span class="c2">salvajemente constante </span
                                ><span class="c1"
                                  >me detengo y algo en esas palabras me hace
                                  sentir nostalgia, me hace extrañar.
                                </span>
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Ahora interrumpe el cansancio de la
                                  enfermedad, no puedo hacer nada porque cuando
                                  empiezo tengo que parar.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Me muero de las iras por todo el desorden a
                                  mi alrededor: voy a colocar las sábanas en la
                                  ropa sucia, pero en ese momento observo la
                                  pasta de dientes en el suelo y me agacho para
                                  recogerla y asoma una telaraña y en esa
                                  telaraña un mosco intenta salvarse, ¿debo
                                  intervenir?</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Y antes de entrar a la ducha, me miro de
                                  reojo al espejo y me detengo porque veo las
                                  dos cicatrices que me cruzan el pecho y a mi
                                  desnudez, no la reconozco.
                                </span>
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Entonces la clase sufre una abrupta
                                  interrupción porque un hombre grita: ¡tienes
                                  un amante! Y todos palidecemos.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Frente al animal enfermo, mi animalito flaco
                                  y la mirada de la niña, digo </span
                                ><span class="c2">hoy hay que hacerlo</span
                                ><span class="c1"
                                  >. Y en esa afirmación rotunda irrumpe una
                                  duda.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Muerta de pena o muerta de hambre, pero tengo
                                  que interrumpir para escucharle otra vez hacer
                                  las mismas preguntas.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Interrumpe entonces un mensaje que dice </span
                                ><span class="c2"
                                  >¿me puedes venir a recoger?</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c2">&nbsp;</span
                                ><span class="c1"
                                  >La abuela, anuncia mi mamá por teléfono
                                  mientras riego las plantas, se murió.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Las dos nos miramos y nos reímos y le digo ¿y
                                  ahora? Me olvidé la mascarilla. Y tenemos que
                                  volver a parar.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Estamos mi papá y yo sentados en la sala de
                                  mi casa y le digo papá estoy embarazada y me
                                  dice tienes que interrumpir el embarazo, los
                                  hijos siempre son un peso</span
                                ><span class="c2">.</span>
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Por fin logro sentarme a escribir, pero
                                  entonces, mi jefa pelirroja me llama y dice:
                                  para hoy el presupuesto, no para mañana, para
                                  hoy.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >El gato tiene un pájaro en la boca, corro, lo
                                  atrapo, interrumpo su voracidad y luego me
                                  disculpo. El pajarito ya no puede volar y yo
                                  no me atrevo ni a matarlo, ni a
                                  salvarlo.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Voy a sentarme a hacer pipí y él grita y
                                  exige con su voz fina: mamá ven ahorita.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Y entonces, el man interrumpe la canción para
                                  poner otra, ¿puedes creer?</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >¿Dónde lo enterramos? Me pregunta, le
                                  respondo, debajo del árbol: y al levantar la
                                  piedra salen disparadas miles de hormigas
                                  azules. Hemos interrumpido su obrar lento y
                                  esforzado, dice.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Está casi listo, casi completo, y se me
                                  ocurre la frase que remata, pero antes de
                                  anotarla mi mamá entra y me pide que le ayude
                                  a ponerse el parche.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Oigo su voz, me saco los audífonos, vuelvo a
                                  dejar lo que estoy haciendo y escucho: ¿Me
                                  mandas la nota para la profe?</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Me bajo el pantalón y pum la sangre. Qué
                                  alivio.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Entonces se cae el plato, lo que faltaba,
                                  oigo el chililín y me levanto para recoger los
                                  pedazos, que nadie se haga daño.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >No irás a interrumpir tus planes por un hijo
                                  o ¿sí?</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Un cartel frente al carro interrumpe mi
                                  visión: fui campeón mundial. Participé en las
                                  olimpiadas. Ayúdeme a alimentar a mis
                                  hijos.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Y el doctor otra vez: señora el coitus
                                  interruptus no es un método
                                  anticonceptivo.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >¡No me interrumpas al hablar! ¡Tú maldita
                                  costumbre de interrumpirme! ¡Siempre!</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Y al contestar, la voz dice: no la quiero
                                  interrumpir pero, ¿ha escuchado sobre el
                                  señor, el único que tiene la potestad de
                                  perdonarnos y darnos siempre una segunda
                                  oportunidad?</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Lo que yo quiero es sentarme a tomar el café,
                                  que ahora ya frío reposa sobre la mesita azul,
                                  pero cuando lo logro, cae sobre mi dedo la
                                  primera gota de lluvia.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Interrumpo todo. Luego salgo corriendo detrás
                                  de él, pero ya no está.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Entonces interrumpe un mensaje, y yo digo, lo
                                  siento este sí tengo que contestar, es de mi
                                  casa y leo: por favor pasa comprando
                                  pan.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0" id="h.gjdgxs">
                                <span class="c1"
                                  >Tomo la pastilla y un pequeño estallido en el
                                  vientre me anuncia no solo el insomnio
                                  porvenir, sino un charco de sangre con formas
                                  y texturas que va a interrumpir con dolor, mi
                                  embarazo.</span
                                >
                              </li>
                              <li class="c0 li-bullet-0">
                                <span class="c1"
                                  >Me meto a la ducha y pienso, tengo derecho a
                                  permanecer en silencio, pero golpean la puerta
                                  y el niño entra y se mete al agua y nuestros
                                  cuerpos desnudos me enternecen y canto con él.
                                </span>
                              </li>
                            </ol>
                            <p class="c4 c5"><span class="c1"></span></p>
                          </div>
                        </template>
                      </modal-content>
                    </div>

                    <div v-if="modalId == 'margaritas'">
                      <modal-content :show-header="true">
                        <template v-slot:header-title>
                          <img src="img/titulos/CASANDRAtrans.png" alt="" />
                        </template>
                        <template v-slot:header-body>
                        </template>
                        <template v-slot:header-right-img>
                          <img
                            src="img/claquetas/CASANDRA/CASANDRA ELEMENTO.png"
                            alt=""
                          />
                        </template>

                        <template v-slot:modal-misc>
                          <div class="max-w-lg mx-auto cassandra-text mt-48">
                            <p>
                              <span class="italic"
                                >Casandra: el poder de la premonición femenina u
                                otras formas de prever (rehecho en
                                pandemia)</span
                              >
                            </p>

                            <p>
                              La consigna consiste en responder a las siguientes
                              preguntas de manera automática y breve con la
                              primera imagen o idea que llegue a ti y que
                              quieras compartir con nosotras.
                            </p>

                            <p>1. ¿Cómo imaginas el fin del mundo?</p>

                            <p>2. ¿Has tenido un sueño premonitorio?</p>

                            <p>3. ¿Has seguido alguna vez una intuición?</p>

                            <p>4. ¿Cómo imaginas tu muerte?</p>

                            <p>5. ¿Has tenido una epifanía
                              <span class="italic">
                                (aparición, manifestación o fenómeno a partir
                                del cual se revela un asunto importante)
                              </span>
                              ¿Puedes describirla?
                            </p>
                            
                          </div>
                          <div class="mt-10">
                            <comments />
                          </div>
                        </template>
                      </modal-content>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalContent from "./modals/ModalContent.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation } from "swiper";
import Comments from "../components/Comments";

// import "swiper/swiper.min.css";
// import "swiper/components/navigation/navigation.min.css";

SwiperCore.use([Navigation]);

export default {
  components: {
    ModalContent,
    Swiper,
    SwiperSlide,
    Comments,
  },
  data: () => ({}),
  props: {
    modalId: null,
  },
  methods: {
    onScroll() {
      var el = this.$el.getElementsByClassName("modal-view")[0];
      el.scrollIntoView();
    },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    },
  },
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
      var texts = document.getElementsByClassName("alternate-colors");

      texts.forEach((text) => {
        var str = text.innerHTML;

        let newBody = "";
        str.split(". ").forEach((word, i) => {
          console.log(word, i);

          if (word == undefined) {
            return;
          }

          if (i % 2 == 0) {
            newBody += '<span style="color:#e874a1ff;">';
            newBody += word;
          } else {
            newBody += '<span style="color:#4a9453ff;">';
            newBody += word;
          }
          newBody += i + 1 != str.split(". ").length ? ". " : "";
          newBody += "</span>";
        });
        text.innerHTML = newBody;
      });
    });
  },
};
</script>

<style>
</style>