import firebase from 'firebase/app';

import "firebase/database";

let config = {
    apiKey: "AIzaSyCZGjpF7xeyjcMzrzwzDRArNEMWrhWHJQY",
    authDomain: "mitomana-collage.firebaseapp.com",
    databaseURL: "https://mitomana-collage-default-rtdb.firebaseio.com/",
    projectId: "mitomana-collage",
    storageBucket: "mitomana-collage.appspot.com",
    messagingSenderId: "997233467251",
    appId: "1:997233467251:web:b58e17c50bc40eb123af06",
    measurementId: "G-7SCLCBBGZ3" // NOTE analytics
};

firebase.initializeApp(config);

export default firebase.database();
