<template>
  <div>
    <!-- button toggles -->
    <!-- <div class="row mt-5">
      <div class="col-5" />
      <div class="col-2">
        <button class="btn btn-primary btn-block" @click.prevent="reset">
          CLEAR
        </button>
      </div>
      <div class="col-5" />
    </div> -->

    <!-- canvas single -->
    <div class="row">
      <div class="col-12">
        <Canvas :canvas-id="'canvas-one'" ref="childCanvas" />
      </div>
    </div>
  </div>
</template>

<script>
import Canvas from "../components/Canvas";

export default {
  name: "Master",
  data: () => ({
    buttons: ["Simple", "Separate", "Replicated"],
    single: true,
  }),
  methods: {
    // NOTE Unused
    reset() {
      this.$refs.childCanvas.reset();
    },
  },
  components: {
    Canvas,
  },
};
</script>
